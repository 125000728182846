import { getSentry } from "@/lib/integrations/sentry/client";

function trackImageLoading() {
  document.querySelectorAll<HTMLImageElement>("img[src]").forEach((element) => {
    try {
      if (new URL(element.src).protocol !== "https:") {
        return;
      }
    } catch (_error) {
      return;
    }

    function loadingError() {
      getSentry().captureException(new Error("Image failed to load"), {
        extra: { src: element.src },
      });
      element.removeEventListener("load", loadingSuccess);
    }

    function loadingSuccess() {
      element.removeEventListener("error", loadingError);
    }

    element.addEventListener("error", loadingError, { once: true });
    element.addEventListener("load", loadingSuccess, { once: true });
  });
}

export { trackImageLoading };
