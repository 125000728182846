function deepToString<
  Values extends Record<string, unknown> = Record<string, unknown>,
>(values: Values, maxDepth = 5): Values {
  return deepToStringObject(values, maxDepth);
}

function deepToStringObject<
  Values extends Record<string, unknown> = Record<string, unknown>,
>(values: Values, maxDepth?: number, depth = 0): Values {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      const escapedKey = key === "title" ? "_title" : key;
      return [escapedKey, deepToStringValue(value, maxDepth, depth)];
    }),
  ) as Values;
}

function deepToStringValue(
  values: unknown,
  maxDepth?: number,
  depth = 0,
): unknown {
  if (maxDepth && depth >= maxDepth) {
    return values;
  }

  if (Array.isArray(values)) {
    return values.map<unknown>((value) =>
      deepToStringValue(value, maxDepth, depth + 1),
    );
  }

  if (typeof values === "object" && values !== null) {
    return deepToStringObject(
      values as Record<string, unknown>,
      maxDepth,
      depth + 1,
    );
  }

  return String(values);
}

export { deepToString };
