import { isbot as isBotUserAgent } from "isbot";

const supportedBrowsers =
  /Edge?\/(79|[89]\d|\d{3,})\.\d+(\.\d+|)|Firefox\/(7[89]|[89]\d|\d{3,})\.\d+(\.\d+|)|Chrom(ium|e)\/(79|[89]\d|\d{3,})\.\d+(\.\d+|)|(Maci|X1{2}).+ Version\/(13\.([1-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+)([,.]\d+|)( \(\w+\)|)( Mobile\/\w+|) Safari\/|Chrome.+OPR\/(9[1-9]|\d{3,})\.\d+\.\d+|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(13[._]([4-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})[._]\d+)([._]\d+|)|Android.+Firefox\/(13\d|1[4-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|Android.+Chrom(ium|e)\/(129|1[3-9]\d|[2-9]\d{2}|\d{4,})\.\d+(\.\d+|)|SamsungBrowser\/(1[3-9]|[2-9]\d|\d{3,})\.\d+/;

function isSupportedBrowser(userAgent = navigator.userAgent) {
  return supportedBrowsers.test(userAgent);
}

function isBot() {
  if (isBotUserAgent(navigator.userAgent)) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return !window || !document.body;
}

export { isBot, isSupportedBrowser };
